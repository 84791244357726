import { FC } from "react";
import { Col, Label, Row } from "reactstrap";
import imgTuxtla from "../../asset/img/icons/tuxtla.png"
import imgForaneos from "../../asset/img/icons/foraneos.png"
import imgBajas from "../../asset/img/icons/bajas.png"
import imgPostulantes from "../../asset/img/icons/postulantes.png"
import Block from "../../components/Block.component";
import colors from "../../config/colors.config";
import img01 from "../../asset/img/img04.jpg";

const Dashboard: FC = () => {
    return (
        <section style={{ width: "100%", height: "100vh", background: `url(${img01}) no-repeat center center/cover fixed`, }} >
            <Block height={50} />
            <Row style={{ marginRight: "20%", marginLeft: "20%", width: "60%" }} >
                <Col style={{ textAlign: "center" }} >
                    <Label style={{ fontSize: 48, color: colors.blue, fontWeight: "bold" }} >
                        Menu
                    </Label>
                </Col>
            </Row>
            <Row style={{ marginRight: "20%", marginLeft: "20%", width: "60%" }} >
                <Col
                    style={{ textAlign: "center", minWidth: "50%", width: "50%", maxWidth: "50%" }}
                    onClick={() => {

                    }}
                >
                    <div
                        style={{ padding: 20, backgroundColor: "#F6F6F6", borderRadius: 12, cursor: "pointer" }}
                        onClick={() => window.location.href = "/tuxtla"}
                    >
                        <Row>
                            <Label style={{ fontSize: 36 }} >
                                Tuxtla
                            </Label>
                        </Row>
                        <Row>
                            <Col>
                                <img src={imgTuxtla} alt="/*-" style={{ width: 150, height: 150 }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{ textAlign: "center", minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                    <div
                        style={{ padding: 20, backgroundColor: "#F6F6F6", borderRadius: 12, cursor: "pointer" }}
                        onClick={() => window.location.href = "/foraneos"}
                    >
                        <Row>
                            <Label style={{ fontSize: 36 }} >
                                Foráneos
                            </Label>
                        </Row>
                        <Row>
                            <Col>
                                <img src={imgForaneos} alt="/*-" style={{ width: 150, height: 150 }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginRight: "20%", marginLeft: "20%", width: "60%", marginTop: 30 }} >
                <Col style={{ textAlign: "center", minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                    <div
                        style={{ padding: 20, backgroundColor: "#F6F6F6", borderRadius: 12, cursor: "pointer" }}
                        onClick={() => window.location.href = "/bajas"}
                    >
                        <Row>
                            <Label style={{ fontSize: 36 }} >
                                Bajas
                            </Label>
                        </Row>
                        <Row>
                            <Col>
                                <img src={imgBajas} alt="/*-" style={{ width: 150, height: 150 }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col style={{ textAlign: "center", minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                    <div
                        style={{ padding: 20, backgroundColor: "#F6F6F6", borderRadius: 12, cursor: "pointer" }}
                        onClick={() => window.location.href = "/postulantes"}
                    >

                        <Row>
                            <Label style={{ fontSize: 36 }} >
                                Postulantes
                            </Label>
                        </Row>
                        <Row>
                            <Col>
                                <img src={imgPostulantes} alt="/*-" style={{ width: 150, height: 150 }} />
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        </section>
    );
}

export default Dashboard