
import ApiConfig from "../config/Api.config";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";

const url = `${ApiConfig.URLBase}/usuario`

const login = async (username: string, password: string): Promise<Response> => {    
    const request: Request = {
        url: `${url}/login`,
        method: "POST",
        data: { username, password }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}


const UsuarioService = {
    login
}

export default UsuarioService;