import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import { FC } from "react";
import Index from "./pages/public/index.page";
import Login from "./pages/private/Login.page";
import Dashboard from "./pages/private/Dashboard.page";
import IndexPostulante from "./pages/private/postulantes/Postulante.page"
import IndexTuxtla from "./pages/private/tuxtla/Tuxtla.page";
import IndexForaneo from "./pages/private/foraneo/Foraneos.page";
import IndexBajas from "./pages/private/baja/Baja.page";
import TyC from "./pages/public/TyC.page";

const App: FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/postulantes" element={<IndexPostulante />} />
        <Route path="/tuxtla" element={<IndexTuxtla />} />
        <Route path="/foraneos" element={<IndexForaneo />} />
        <Route path="/bajas" element={<IndexBajas />} />

        <Route path="/tyc" element={<TyC verNavBar={true} />} />

      </Routes>
    </Router>
  );
}

export default App;
