
const convertirBase64 = async (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            // Convierte el contenido del archivo a una cadena base64
            const base64String = reader.result as string;
            // Embebe el nombre del archivo en la cadena base64
            const embeddedBase64String = `filename=${file.name};base64,${base64String.split(',')[1]}`;
            resolve(embeddedBase64String);
        };

        reader.onerror = () => {
            reject(reader.error);
        };

        // Lee el contenido del archivo como una URL de datos (base64)
        reader.readAsDataURL(file);
    });
};

const obtenerNombreArchivo = (data: string): any => {
    if (typeof data !== 'string') return ""
    // Extraer el nombre del archivo y la cadena base64
    const fileNameMatch = data.match(/filename=([^;]+);base64,/) ?? "";
    const fileName = fileNameMatch[1];
    return fileName;
}

const descargarArchivoDesdeBase64 = (data: string | number | undefined) => {
    if (typeof data !== 'string') return
    // Extraer el nombre del archivo y la cadena base64
    const fileNameMatch = data.match(/filename=([^;]+);base64,/);
    const base64Match = data.match(/base64,(.*)/);

    if (!fileNameMatch || !base64Match) {
        throw new Error("Invalid data format");
    }

    const fileName = fileNameMatch[1];
    const base64Data = base64Match[1];
    const contentType = data.substring(data.indexOf('data:') + 5, data.indexOf(';base64'));

    // Convertir la cadena base64 a un Blob
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: contentType });

    // Crear un objeto URL a partir del Blob
    const url = URL.createObjectURL(blob);

    // Crear un enlace y hacer clic en él programáticamente para iniciar la descarga
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Liberar el objeto URL
    URL.revokeObjectURL(url);
};

const ArchivoTool = {
    convertirBase64,
    descargarArchivoDesdeBase64,
    obtenerNombreArchivo
}

export default ArchivoTool;