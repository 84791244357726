import { FC, useState } from "react";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";
import NavBar from "../../components/public/NavBar.components";
import { Row, Col, Label } from "reactstrap"
import Block from "../../components/Block.component";
import colors from "../../config/colors.config";

const TyC: FC<{ verNavBar: boolean }> = ({ verNavBar }) => {

    const [data] = useState<Device>(DeviceConfig.getData());

    return (
        <>
            <NavBar />
            <section
                style={{ width: "100%", height: data.isDesktop ? "100vh" : "150vh", backgroundColor: "#F6F6F6" }}
            >
                <Block height={70} />
                <Row style={{ marginTop: 70, marginLeft: data.isDesktop ? 50 : 5, marginRight: data.isDesktop ? 50 : 5 }} >
                    <Col>
                        <Row>
                            <Label style={{ fontSize: 36, color: colors.blue }} >
                                Términos y Condiciones
                            </Label>
                        </Row>
                        <Row>
                            <p style={{ fontSize: 28, textAlign: "justify" }} >
                                Bienvenido a ORVE. Al acceder o utilizar nuestro sitio web, aceptas cumplir con los siguientes términos y condiciones:
                            </p>
                        </Row>
                        <Row>
                            <Label style={{ fontSize: 26, color: colors.blue }} >
                                1. Uso de la Información
                            </Label>
                        </Row>
                        <Row>
                            <p style={{ fontSize: 20, textAlign: "justify" }} >
                                Toda la información y documentos proporcionados en este sitio web se utilizarán exclusivamente
                                para llevar un control y administración adecuada de los mismos. Nos comprometemos a proteger
                                la confidencialidad y seguridad de tu información personal.
                            </p>
                        </Row>
                        <Row>
                            <Label style={{ fontSize: 26, color: colors.blue }} >
                                2. Propiedad Intelectual
                            </Label>
                        </Row>
                        <Row>
                            <p style={{ fontSize: 20, textAlign: "justify" }} >
                                Todo el contenido, incluidos textos, imágenes, logotipos y gráficos, es propiedad de ORVE y
                                está protegido por las leyes de derechos de autor. No se permite la reproducción o distribución
                                del contenido sin nuestro consentimiento previo por escrito.
                            </p>
                        </Row>
                    </Col>
                </Row>
                <Block height={70} />
            </section>
        </>
    );

};

export default TyC;