import { FC, useEffect, useState } from "react"
import { Button, Col, Offcanvas, OffcanvasBody, OffcanvasHeader, Row } from "reactstrap"
import logo from "../../asset/img/logo.jpg";
import colors from "../../config/colors.config";
import { IconButton, } from "@material-ui/core";
import MenuIcon from '@mui/icons-material/Menu';
import { Close, Facebook, YouTube } from "@material-ui/icons";
import DeviceConfig from "../../config/Device.config";
import Device from "../../interfaces/Device.interface";
// import { Groups, Handshake } from "@mui/icons-material";
// import Components from "../tools/Components.tool";
import Tiempo from "../../tools/Tiempo.tool";
import { useLocation } from 'react-router-dom';

interface LabelButton { label: string, index: number, /*icon: any*/ }

const NavBar: FC = () => {

    const [isOverButton, setIsOverButton] = useState<any>({ 0: false, 1: false });
    const [data, setData] = useState<Device>(DeviceConfig.getData());
    const [isOpenCanvas, setIsOpenCanvas] = useState<boolean>(false);

    const location = useLocation();

    const handleMouseButton = (index: number, value: boolean) => {
        setIsOverButton((prev: any) => ({ ...prev, [index]: value }));
    };

    useEffect(() => {
        const data: Device = DeviceConfig.getData();
        setData(data);
    }, []);

    const arrayLabelButton: LabelButton[] = [
        {
            label: "Inicio",
            index: 0,
            // icon: <Home />
        },
        {
            label: "Acerca de Nosotros",
            index: 1,
            // icon: <ShoppingCart />
        },
        {
            label: "Tu bienestar es nuestra motivación",
            index: 3,
            // icon: <Groups />
        },
        {
            label: "Seguridad y Vigilancia Especializada",
            index: 4,
            // icon: <Handshake />
        },
        {
            label: "Contáctanos",
            index: 5,
            // icon: <ContactPhone />
        },
        {
            label: "Visítanos",
            index: 6,
            // icon: <ContactPhone />
        },
        {
            label: "Términos y Condiciones",
            index: 7,
            // icon: <ContactPhone />
        },
    ]

    const onClickButton = (index: number) => {
        console.log('Current URL:', location.pathname);
        const value: boolean = location.pathname === "/tyc"
        switch (index) {
            case 0:
                window.location.href = value ? "/#home" : "#home";
                break;
            case 1:
                window.location.href = value ? "/#acerca-de-nosotros" : "#acerca-de-nosotros";
                break;
            case 3:
                window.location.href = value ? "/#tu-bienestar" : "#tu-bienestar";
                break;
            case 4:
                window.location.href = value ? "/#seguridad-y-vigilancia-especializada" : "#seguridad-y-vigilancia-especializada";
                break;
            case 5:
                window.location.href = value ? "/#contacto" : "#contacto";
                Tiempo.sleep(0.3).then(() => { window.location.href = "#contacto"; })
                break;
            case 6:
                window.location.href = value ? "/#mapa" : "#mapa";
                break;
            case 7:
                window.location.href = "/tyc";
                break;
            default:
                break;

        }
    }

    return (
        data.isDesktop ?
            < Row style={{ zIndex: 99, marginRight: 0, marginLeft: 0, height: 70, position: "fixed", width: "100%", backgroundColor: 'rgba(255, 255, 255)', backdropFilter: 'blur(5px)', }} >
                <Col style={{ textAlign: "left", minWidth: "100%", width: "100%", maxWidth: "100%", paddingLeft: 10, }} >
                    {
                        arrayLabelButton.map((item: LabelButton) => {
                            const { index, label } = item;
                            return (
                                <Button
                                    style={{
                                        borderBottom: isOverButton[index] ? `3px solid ${colors.blue}` : '3px solid transparent',
                                        color: colors.blue,
                                        marginTop: 14,
                                        fontWeight: "bold",
                                        fontSize: 18,
                                        backgroundColor: "transparent",
                                        borderColor: "transparent",
                                        boxShadow: "none",
                                    }}
                                    onClick={() => onClickButton(index)}
                                    onMouseEnter={() => handleMouseButton(index, true)}
                                    onMouseLeave={() => handleMouseButton(index, false)}
                                >
                                    {label}
                                </Button>
                            )
                        })
                    }
                </Col>

            </Row >
            :
            <>
                < Row style={{ zIndex: 99, marginRight: 0, marginLeft: 0, height: 75, backgroundColor: colors.white, position: "fixed", width: "100%" }} >
                    {/* <Col>
                        <img src={logo} alt="/*-" style={{ height: 60, marginTop: 10, borderRadius: "50%" }} />
                    </Col> */}
                    <Col style={{ textAlign: "right" }} >
                        {/*
                        <IconButton
                            style={{ height: 50, width: 50, marginTop: 5, marginRight: 7 }}
                            onClick={() => { window.open("https://www.facebook.com/profile.php?id=100091784388906") }}
                            title={"Haz click aquí para ver nuestra pagina de FacoBook"}
                        >
                            <Facebook style={{ height: 35, width: 35, color: colors.blue }} />
                        </IconButton>
                        <IconButton
                            style={{ height: 50, width: 50, marginTop: 5, marginRight: 7 }}
                            onClick={() => { window.open("https://www.youtube.com/@Agro-Mexicano") }}
                            title={"Haz click aquí para ver nuestro canal de YouTube"}
                        >
                            <YouTube style={{ height: 35, width: 35, color: colors.blue }} />
                        </IconButton>
                        */}
                        <Button
                            style={{ backgroundColor: "transparent", height: 65, marginTop: 5, borderColor: "transparent", boxShadow: "none" }}
                            onClick={() => setIsOpenCanvas(true)}
                        >
                            <MenuIcon style={{ color: colors.blue, height: 30, width: 30, }} />
                        </Button>
                    </Col>
                </Row >
                <Offcanvas
                    isOpen={isOpenCanvas}
                    direction="end"
                >
                    <OffcanvasHeader
                        close={
                            <Button
                                style={{ backgroundColor: "transparent", height: 65, marginTop: 5, borderColor: "transparent" }}
                                onClick={() => setIsOpenCanvas(false)}
                            >
                                <Close style={{ color: colors.blue, height: 35, width: 35, }} />
                            </Button>
                        }
                    >
                        <Row>
                            <Col style={{ minWidth: "10%", width: "10%", maxWidth: "10%" }} >
                                <img src={logo} alt="/*-" style={{ height: 50, marginTop: 3, borderRadius: "50%" }} />
                            </Col>
                        </Row>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        {
                            arrayLabelButton.map((item: LabelButton) => {
                                const { index, label } = item;
                                return (
                                    <Row style={{ marginRight: 0, marginLeft: 0 }}>
                                        <Col style={{ textAlign: "left" }} >
                                            <Button
                                                style={{ textAlign: "left", backgroundColor: "transparent", marginTop: 5, borderColor: "transparent", color: colors.blue, fontSize: 24, textDecorationLine: "underline" }}
                                                onClick={() => {
                                                    setIsOpenCanvas(false);
                                                    onClickButton(index);
                                                }}
                                            >
                                                {label}
                                            </Button>
                                        </Col>
                                    </Row>
                                );
                            })

                        }

                    </OffcanvasBody>
                </Offcanvas>
            </>
    )
}

export default NavBar;