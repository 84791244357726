import { ItemListBox } from "../interfaces/Items.interface";
import Postulante from "../interfaces/Postulante.interface";

const getEstructura = (): Postulante => {
    const postulante: Postulante = {
        IdPostulante: 0,
        actaNacimiento: "",
        certificadoEstudios: "",
        comprobanteDomicilio: "",
        CURP: "",
        fecha: "",
        hora: "",
        INE: "",
        materno: "",
        nombre: "",
        numeroCelular: "",
        paterno: "",
        RFC: "",
        status: "I",
        principal: "POSTULANTE",
        secundario: "POSTULANTE",
        observaciones: "",
        facultades: "",
        cartaRecomendacion: "",
        solicitudEmpleoAtras: "",
        solicitudEmpleoFrente: ""
    };
    return postulante
}

const contadorArchivosCargados = (postulante: Postulante): number => {
    let counter: number = 0;
    if (postulante.actaNacimiento && postulante.actaNacimiento.length > 0) counter++;
    if (postulante.INE && postulante.INE.length > 0) counter++;
    if (postulante.CURP && postulante.CURP.length > 0) counter++;
    if (postulante.comprobanteDomicilio && postulante.comprobanteDomicilio.length > 0) counter++;
    if (postulante.certificadoEstudios && postulante.certificadoEstudios.length > 0) counter++;
    if (postulante.RFC && postulante.RFC.length > 0) counter++;
    if (postulante.solicitudEmpleoFrente && postulante.solicitudEmpleoFrente.length > 0) counter++;
    if (postulante.solicitudEmpleoAtras && postulante.solicitudEmpleoAtras.length > 0) counter++;
    if (postulante.cartaRecomendacion && postulante.cartaRecomendacion.length > 0) counter++;
    return counter;
}

const getListaTuxtla = (): ItemListBox[] => {
    const list: ItemListBox[] = [
        {
            index: "SEDE",
            value: "SEDE"
        },
        {
            index: "CAMPUS 1",
            value: "CAMPUS 1"
        },
        {
            index: "Metropolitano",
            value: "Metropolitano"
        },
        {
            index: "Ciudad Universitaria",
            value: "Ciudad Universitaria"
        },
        {
            index: "Otros servicios",
            value: "Otros servicios"
        },
    ]
    return list
}

const getListaForaneo = (): ItemListBox[] => {
    const list: ItemListBox[] = [
        {
            index: "San Cristobal",
            value: "San Cristóbal"
        },
        {
            index: "Tapachula",
            value: "Tapachula"
        },
        {
            index: "Villaflores",
            value: "Villaflores"
        },
        {
            index: "Catazaja",
            value: "Catazaja"
        },
        {
            index: "Mezcalapa",
            value: "Mezcalapa"
        },
        {
            index: "Ocozocoautla",
            value: "Ocozocoautla"
        },
        {
            index: "Tonala",
            value: "Tonala"
        },
        {
            index: "Comitan",
            value: "Comitan"
        },
        {
            index: "Pijijiapan",
            value: "Pijijiapan"
        },
        {
            index: "Arriaga",
            value: "Arriaga"
        },
        {
            index: "Pichuclaco",
            value: "Pichuclaco"
        },
        {
            index: "Emiliano Zapata",
            value: "Emiliano Zapata"
        },
    ]
    return list
}

const PostulanteModel = {
    getEstructura,
    getListaTuxtla,
    getListaForaneo,
    contadorArchivosCargados,
}

export default PostulanteModel;
