
import ApiConfig from "../config/Api.config";
import Formulario from "../interfaces/Formulario.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import Tiempo from "../tools/Tiempo.tool";

const url = `${ApiConfig.URLBase}/formulario`

const guardar = async (formulario: Formulario): Promise<Response> => {
    const fecha = Tiempo.getCurrentDate();
    const hora = Tiempo.getCurrentTime();
    formulario = {
        ...formulario,
        fecha,
        hora
    }
    const request: Request = {
        url: `${url}/guardar`,
        method: "POST",
        data: { formulario }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar`,
        method: "POST",
        data: {}
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const FormularioService = {
    guardar,
    listar
}

export default FormularioService;