
const colors = {
    white: "#FFFFFF",
    black: "#000000",
    blue: "#014F93",
    blue01: "#3680C0",
    blueLink: "#99C3FF",
    red: "#F73F3F",
    warning: "#F9AB08",
    grey: "grey"
}

export default colors;