import { KeyboardEvent, ChangeEvent, FC, useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify"
import Block from "../../../components/Block.component";
import { Button, Card, Col, DropdownItem, Input, InputGroup, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { IconButton, FormGroup } from "@material-ui/core";
import colors from "../../../config/colors.config";
import { Add, ArrowBack, Cached, Clear, Close, Delete, Edit, Search } from "@material-ui/icons";
import Components from "../../../tools/Components.tool";
import Response from "../../../interfaces/Response.interface";
import PostulanteService from "../../../services/Postulante.service";
import Postulante from "../../../interfaces/Postulante.interface";
import Table, { TableColumn } from 'react-data-table-component';
import Tiempo from "../../../tools/Tiempo.tool";
import Fecha from "../../../tools/Fecha.tool";
import Swal from "sweetalert2";
import PostulanteModel from "../../../models/Postulante.model";
import iconFile from "../../../asset/img/icons/iconFile.png"
import iconFileAdd from "../../../asset/img/icons/iconFileAdd.png"
import { Download } from "@mui/icons-material";
import ArchivoTool from "../../../tools/Archivo.tool";
import { ItemListBox } from "../../../interfaces/Items.interface";
import Numero from "../../../tools/Numero.tool";
import gifArchivo from "../../../asset/animation/archivo.gif";
import gifDescargar from "../../../asset/animation/descargar.gif";
import NavBarDashboard from "../../../components/private/NavBarDashboard.components";

interface ParametrosBusqueda { textoBuscador: string, principal: string, secundario: string }

const IndexBaja: FC = () => {

    const [postulantesLista, setPostulantesLista] = useState<Postulante[]>([]);
    const [parametrosBusqueda, setParametrosBusqueda] = useState<ParametrosBusqueda>({ textoBuscador: "", principal: "0", secundario: "0" });
    const [hora, setHora] = useState<string>("");
    const [archivosCargados, setArchivosCargados] = useState<number>(0);
    const [isOpenModalPostulante, setIsOpenModalPostulante] = useState<boolean>(false);
    const [postulante, setPostulante] = useState<Postulante>(PostulanteModel.getEstructura());
    const [listBox, setListBox] = useState<ItemListBox[]>([...PostulanteModel.getListaTuxtla(), ...PostulanteModel.getListaForaneo()]);
    const [mostrar, setMostrar] = useState<boolean>(false);
    const [cargandoArchivos, setCargandoArchivos] = useState<boolean>(false);
    const [cargandoTabla, setCargandoTabla] = useState<boolean>(false);

    const columnsPostulante: TableColumn<Postulante>[] = [
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Fecha </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{Fecha.formatDate(postulante.fecha)}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Nombre(s) </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.nombre}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Apellido Paterno </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.paterno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Apellido Materno </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.materno}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Numero de Celular </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.numeroCelular}</Label>,
        },
        // {
        //     name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Archivos Cargados </Label>,
        //     cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{contadorArchivosCargados(postulante)} / 6</Label>,
        // },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Area </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.principal}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} > Sub Area </Label>,
            cell: (postulante: Postulante) => <Label style={{ color: colors.black, fontSize: 16, whiteSpace: 'normal' }} >{postulante.secundario}</Label>,
        },
        {
            name: <Label style={{ fontWeight: "", color: colors.black, fontSize: 18 }} >  </Label>,
            cell: (postulante: Postulante) =>
                <>
                    <IconButton style={{ color: colors.red }} onClick={() => {
                        eliminarPostulante(postulante)
                    }}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton style={{ color: colors.blue }} onClick={() => {
                        setIsOpenModalPostulante(true);
                        setPostulante(postulante)
                        let counter: number = PostulanteModel.contadorArchivosCargados(postulante);
                        setArchivosCargados(counter);
                    }}
                    >
                        <Edit />
                    </IconButton>
                </>,
        },
    ];

    useEffect(() => {
        cargarDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handlePostulanteData = (name: string, value: string) => {
        setPostulante((prev: Postulante) => ({ ...prev, [name]: value }));
    }

    const cargarDatos = async () => {
        listarPostulantes(parametrosBusqueda);
        Components.focus("textoBuscador");
    }

    const listarPostulantes = async (parametrosBusqueda: ParametrosBusqueda): Promise<void> => {
        setCargandoTabla(true);
        const { textoBuscador, principal, secundario } = parametrosBusqueda;
        const response: Response = await PostulanteService.listarBaja(textoBuscador, principal, secundario);
        if (response.error === false) {
            const postulantesLista: Postulante[] = response.data.value;
            setPostulantesLista(postulantesLista);
            setHora(Tiempo.getCurrentTimeFormat12Hour());
            setCargandoTabla(false);
        }
    }

    const guardarDatosPostulante = async (): Promise<void> => {
        await cargarDatos();
        const response: Response = await PostulanteService.guardar(postulante);
        const { error } = response;
        if (error === false) {
            let counter: number = PostulanteModel.contadorArchivosCargados(postulante);
            setArchivosCargados(counter);
            toast.info("Datos guardados");
            listarPostulantes(parametrosBusqueda);
        }
    }

    const obtenerPostulante = async (IdPostulante: number): Promise<Postulante> => {
        setCargandoArchivos(true);
        const response = await PostulanteService.obtener(IdPostulante);
        // await Tiempo.sleep(3)
        const { error } = response;
        let postulante: Postulante = PostulanteModel.getEstructura();
        if (error === false) {
            const { data: { value } } = response;
            postulante = value;
        }
        setCargandoArchivos(false)
        return postulante;
    }

    const eliminarPostulante = async (postulante: Postulante): Promise<void> => {
        Swal.fire({
            icon: "question",
            title: "Eliminar Postulante",
            text: `¿Estas seguro de eliminar al postulante ${postulante.nombre} ${postulante.paterno} ${postulante.materno}?`,
            showConfirmButton: true,
            confirmButtonColor: colors.red,
            showCancelButton: true,
            cancelButtonText: "No Eliminar",
            confirmButtonText: "Si Eliminar",
            timerProgressBar: true,
            reverseButtons: true
        }).then(async (response) => {
            if (response.isConfirmed) {
                const response: Response = await PostulanteService.eliminar(postulante.IdPostulante);
                if (response.error === false) {
                    toast.info("Postulante eliminado");
                    listarPostulantes(parametrosBusqueda);
                }
            }
        })
    }

    return (
        <>
            <ToastContainer position="bottom-left" autoClose={1500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <section style={{ width: "100%", height: "100vh" }} >
                <NavBarDashboard />
                <Block height={70} />
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 30 }} >
                    <Col>
                        <IconButton
                            style={{ backgroundColor: colors.blue, color: colors.white, marginRight: 10 }}
                            onClick={() => { window.location.href = "/dashboard"; }}
                        >
                            <ArrowBack />
                        </IconButton>
                    </Col>
                </Row>
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 30 }} >
                    <Col>
                        <Label style={{ color: colors.blue, fontSize: 36, fontWeight: "bold" }} >
                            Lista de Bajas
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 10 }} >
                    <Col style={{ minWidth: "70%", width: "70%", maxWidth: "70%" }} >
                        <Row>
                            <Col style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                                <FormGroup>
                                    <Label>
                                        Buscar en Bajas
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="textoBuscador"
                                            value={parametrosBusqueda.textoBuscador}
                                            placeholder="Nombre(s), Apellidos"
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                setParametrosBusqueda((prev: ParametrosBusqueda) => ({ ...prev, textoBuscador: value }));

                                            }}
                                            onKeyDown={({ key }: KeyboardEvent<HTMLInputElement>) => {
                                                if (key === "Enter") listarPostulantes(parametrosBusqueda);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                                // marginTop: -5
                                            }}
                                        />
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderLeftWidth: 0,
                                                borderBottomWidth: 2,
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                Components.focus("textoBuscador");
                                                setParametrosBusqueda((prev: ParametrosBusqueda) => ({ ...prev, textoBuscador: "" }));
                                            }}
                                        >
                                            <Clear style={{ color: colors.blue }} />
                                        </InputGroupText>
                                        <InputGroupText
                                            style={{
                                                backgroundColor: "transparent",
                                                borderLeftWidth: 0,
                                                borderBottomWidth: 2,
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                listarPostulantes(parametrosBusqueda);
                                                Components.focus("textoBuscador");
                                            }}
                                        >
                                            <Search style={{ color: colors.blue }} />
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                            <Col style={{ minWidth: "25%", width: "25%", maxWidth: "25%" }} >
                                <FormGroup>
                                    <Label >
                                        AREA
                                    </Label>
                                    <Input
                                        id=""
                                        type="select"
                                        value={parametrosBusqueda.principal}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setParametrosBusqueda((prev: ParametrosBusqueda) => ({ ...prev, principal: value }));
                                            Tiempo.sleep(1).then(() => {
                                                const { textoBuscador, secundario } = parametrosBusqueda;
                                                const principal: string = value;
                                                listarPostulantes({ textoBuscador, principal, secundario });
                                                if (value === "TUXTLA") setListBox(PostulanteModel.getListaTuxtla());
                                                if (value === "FORANEO") setListBox(PostulanteModel.getListaForaneo());
                                                if (value === "0") setListBox([...PostulanteModel.getListaTuxtla(), ...PostulanteModel.getListaForaneo()]);
                                            });
                                        }}
                                        style={{
                                            borderWidth: 2,
                                            boxShadow: "none",
                                            borderLeftColor: "transparent",
                                            borderTopColor: "transparent",
                                            borderRightColor: "transparent",
                                            borderBottomColor: colors.blue,
                                        }}
                                    >
                                        <option value="0">TODOS</option>
                                        <option value="TUXTLA">TUXTLA</option>
                                        <option value="FORANEO">FÓRANEOS</option>
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col style={{ minWidth: "25%", width: "25%", maxWidth: "25%" }} >
                                <FormGroup>
                                    <Label >
                                        Sub Area
                                    </Label>
                                    <Input
                                        id=""
                                        type="select"
                                        value={parametrosBusqueda.secundario}
                                        onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                            setParametrosBusqueda((prev: ParametrosBusqueda) => ({ ...prev, secundario: value }));
                                            Tiempo.sleep(1).then(() => {
                                                const { textoBuscador, principal } = parametrosBusqueda;
                                                const secundario: string = value;
                                                listarPostulantes({ textoBuscador, principal, secundario });
                                            });
                                        }}
                                        style={{
                                            borderWidth: 2,
                                            boxShadow: "none",
                                            borderLeftColor: "transparent",
                                            borderTopColor: "transparent",
                                            borderRightColor: "transparent",
                                            borderBottomColor: colors.blue,
                                        }}
                                    >
                                        <option value={"0"}>TODOS</option>
                                        {
                                            listBox.map((item: ItemListBox) => {
                                                const { index, value } = item;
                                                return (
                                                    <option value={index}>{value}</option>
                                                )
                                            })
                                        }
                                    </Input>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ textAlign: "right", minWidth: "30%", width: "30%", maxWidth: "30%" }} >
                        <IconButton
                            style={{ backgroundColor: colors.blue, color: colors.white, marginRight: 10, marginTop: 25 }}
                            onClick={() => {
                                listarPostulantes(parametrosBusqueda);
                            }}
                        >
                            <Cached />
                        </IconButton>
                        <IconButton
                            style={{ backgroundColor: colors.blue, color: colors.white, marginRight: 10, marginTop: 25 }}
                            onClick={() => {
                                setIsOpenModalPostulante(true);
                                setPostulante(PostulanteModel.getEstructura());
                            }}
                        >
                            <Add />
                        </IconButton>
                    </Col>
                </Row >
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 10 }} >
                    <Col>
                        <Label style={{ fontWeight: "bold" }} >
                            Ultima Actualización: {hora}
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 30 }} >
                    <Label>
                        Se encontraron {postulantesLista.length} dados de baja <br /><br />
                        Cuando se muestre la pagina por primera vez se listaran solo los últimos 100 dados de baja <br />
                        Si quieres a una persona en especifico haz la búsqueda.
                    </Label>
                </Row>
                <Row style={{ marginRight: 50, marginLeft: 50, marginTop: 5 }} >
                    <Col>
                        {
                            cargandoTabla ?
                                <>
                                    <center>
                                        <img src={gifDescargar} alt="/*-" style={{ width: 300, height: 300 }} />
                                    </center>
                                </>
                                :
                                <Table
                                    columns={columnsPostulante}
                                    data={postulantesLista}
                                    noDataComponent={<Label style={{ fontWeight: "bold", fontSize: 18, color: colors.grey }} >Tabla Vacía</Label>}
                                />
                        }
                    </Col>
                </Row>
            </section >
            <Modal
                isOpen={isOpenModalPostulante}
                style={{ maxWidth: "90%", fontFamily: '"Fira Sans", sans-serif' }}
                onOpened={async () => {
                    Components.focus("postulante.nombre");
                    const data = await obtenerPostulante(postulante.IdPostulante);
                    setPostulante((prev: Postulante) => ({ ...prev, ...data }));
                    let counter: number = PostulanteModel.contadorArchivosCargados(data);
                    setArchivosCargados(counter);
                }}
            >
                <ModalHeader
                    style={{ backgroundColor: "#014F93" }}
                    close={
                        <Col style={{ textAlign: "right" }} >
                            <IconButton
                                onClick={async () => {
                                    Components.focus("postulante.nombre");
                                    const data = await obtenerPostulante(postulante.IdPostulante);
                                    setPostulante((prev: Postulante) => ({ ...postulante, ...data }));
                                }}
                                style={{ backgroundColor: colors.white, color: colors.blue, marginRight: 15 }}
                            >
                                <Cached />
                            </IconButton>
                            <IconButton
                                onClick={() => { setIsOpenModalPostulante(false); }}
                                style={{ backgroundColor: colors.white, color: colors.blue }}
                            >
                                <Close />
                            </IconButton>
                        </Col>
                    }
                >
                    <Label style={{ fontSize: 28, color: colors.white }} >
                        Datos del Elemento
                    </Label>
                </ModalHeader>

                <ModalBody style={{ minHeight: "70vh", height: "70vh", maxHeight: "70vh", overflowY: "scroll" }} >
                    <Row>
                        <Col style={{}} >
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            Nombre(s)
                                        </Label>
                                        <Input
                                            id="postulante.nombre"
                                            value={postulante.nombre}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("nombre", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,// borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            Apellido Paterno
                                        </Label>
                                        <Input
                                            id="postulante.nombre"
                                            value={postulante.paterno}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("paterno", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>

                                <Col>
                                    <FormGroup>
                                        <Label>
                                            Apellido Materno
                                        </Label>
                                        <Input
                                            id="postulante.materno"
                                            value={postulante.materno}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("materno", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label>
                                            Celular
                                        </Label>
                                        <Input
                                            id="postulante.numeroCelular"
                                            value={postulante.numeroCelular}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 13) return;
                                                value = Numero.formatPhoneNumber(value);
                                                handlePostulanteData("numeroCelular", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {
                        cargandoArchivos && postulante.IdPostulante > 0 ?
                            <>
                                <Row style={{ marginTop: 30 }} >
                                    <Col style={{ textAlign: "center" }} >
                                        {/* <Lottie
                                            options={{ loop: true, autoplay: true, animationData: JSONloadedFiles, rendererSettings: { preserveAspectRatio: 'xMidYMid slice' } }}
                                            height={300} width={300}
                                        /> */}

                                        <img src={gifArchivo} alt="/*-" style={{ width: 300, height: 300 }} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: "center" }} >
                                        <Label style={{ color: colors.blue, fontSize: 18 }} >
                                            Descargando documentos, espere por favor...
                                        </Label>
                                    </Col>
                                </Row>
                            </>
                            :
                            <>
                                <Row style={{ marginTop: 30 }} >
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Acta de Nacimiento
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.actaNacimiento === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("actaNacimiento")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.actaNacimiento !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, actaNacimiento: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.actaNacimiento)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.actaNacimiento === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.actaNacimiento ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="actaNacimiento"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, actaNacimiento: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    INE
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.INE === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("INE")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.INE !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, INE: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.INE)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.INE === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.INE ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="INE"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, INE: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    CURP
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.CURP === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("CURP")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.CURP !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, CURP: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.CURP)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.CURP === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.CURP ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="CURP"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, CURP: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Comprobante de Domicilio (Menor a 3 meses)
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.comprobanteDomicilio === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("comprobanteDomicilio")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.comprobanteDomicilio !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, comprobanteDomicilio: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.comprobanteDomicilio)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.comprobanteDomicilio === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.comprobanteDomicilio ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="comprobanteDomicilio"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, comprobanteDomicilio: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Certificado de Estudios
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.certificadoEstudios === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("certificadoEstudios")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.certificadoEstudios !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, certificadoEstudios: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.certificadoEstudios)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.certificadoEstudios === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.certificadoEstudios ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="certificadoEstudios"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, certificadoEstudios: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    RFC
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.RFC === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("RFC")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.RFC !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, RFC: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.RFC)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.RFC === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.RFC ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="RFC"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, RFC: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Solicitud de Empleo Frente
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.solicitudEmpleoFrente === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("solicitudEmpleoFrente")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.solicitudEmpleoFrente !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoFrente: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.solicitudEmpleoFrente)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.solicitudEmpleoFrente === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.solicitudEmpleoFrente ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="solicitudEmpleoFrente"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoFrente: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Solicitud de Empleo Atrás
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.solicitudEmpleoAtras === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("solicitudEmpleoAtras")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.solicitudEmpleoAtras !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoAtras: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.solicitudEmpleoAtras)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.solicitudEmpleoAtras === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.solicitudEmpleoAtras ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="solicitudEmpleoAtras"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoAtras: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                    <Col sm="4" md="4" lg="4" className="mb-3"  >
                                        <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                            <FormGroup>
                                                <Label style={{ color: colors.black, fontSize: 16 }} >
                                                    Carta de Recomendación
                                                </Label>
                                                <DropdownItem divider />
                                                <Row style={{ marginBottom: 0 }} >
                                                    <Col>
                                                        <img
                                                            src={postulante.cartaRecomendacion === "" ? iconFileAdd : iconFile}
                                                            alt="/*-"
                                                            style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                            onClick={() => {
                                                                Components.click("cartaRecomendacion")
                                                            }}
                                                        />
                                                    </Col>
                                                    {
                                                        postulante.cartaRecomendacion !== "" &&
                                                        <>
                                                            <Col style={{ textAlign: "right" }} >
                                                                <IconButton
                                                                    style={{ color: colors.red, }}
                                                                    onClick={() => setPostulante((prev: Postulante) => ({ ...prev, cartaRecomendacion: "" }))}
                                                                >
                                                                    <Delete />
                                                                </IconButton>

                                                                <IconButton
                                                                    style={{ color: colors.blue, marginLeft: 10 }}
                                                                    onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.cartaRecomendacion)}
                                                                >
                                                                    <Download />
                                                                </IconButton>

                                                            </Col>
                                                        </>
                                                    }
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Label>
                                                            {postulante.cartaRecomendacion === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.cartaRecomendacion ?? "")}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Input
                                                    id="cartaRecomendacion"
                                                    type="file"
                                                    accept="application/pdf"
                                                    onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                                        if (files !== null) {
                                                            const file: File = files[0]
                                                            try {
                                                                const base64: string = await ArchivoTool.convertirBase64(file);
                                                                setPostulante((prev: Postulante) => ({ ...prev, cartaRecomendacion: base64 }))
                                                            } catch (error) {
                                                                console.error({ error })
                                                            }
                                                        }
                                                    }}
                                                    hidden
                                                />
                                            </FormGroup>

                                        </Card>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label>
                                            Archivos cargados {archivosCargados} / 6
                                        </Label>
                                    </Col>
                                </Row>
                            </>
                    }

                    <DropdownItem divider />
                    <Row>
                        <Col>
                            <Label style={{ fontSize: 28, color: colors.blue }} >
                                Datos para mover
                            </Label>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }}>
                            <FormGroup>
                                <Label >
                                    Area
                                </Label>
                                <Input
                                    id=""
                                    type="select"
                                    value={postulante.principal}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value === "POSTULANTE") { handlePostulanteData("principal", "POSTULANTE"); handlePostulanteData("secundario", value); handlePostulanteData("status", "I"); setMostrar(false); };
                                        if (value === "TUXTLA") { setListBox(PostulanteModel.getListaTuxtla()); handlePostulanteData("status", "A"); handlePostulanteData("principal", "TUXTLA"); handlePostulanteData("secundario", "SEDE"); setMostrar(true); }
                                        if (value === "FORANEO") { setListBox(PostulanteModel.getListaForaneo()); handlePostulanteData("status", "A"); handlePostulanteData("principal", "FORANEO"); handlePostulanteData("secundario", "San Cristobal"); setMostrar(true); }
                                    }}
                                    style={{
                                        borderWidth: 2,
                                        boxShadow: "none",
                                        borderLeftColor: "transparent",
                                        borderTopColor: "transparent",
                                        borderRightColor: "transparent",
                                        borderBottomColor: colors.blue,
                                    }}
                                >
                                    <option value="POSTULANTE">POSTULANTE</option>
                                    <option value="TUXTLA">TUXTLA</option>
                                    <option value="FORANEO">FORÁNEO</option>
                                </Input>
                            </FormGroup>
                        </Col>
                        {
                            mostrar &&
                            <>
                                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }}>
                                    <FormGroup>
                                        <Label >
                                            Sub Area
                                        </Label>
                                        <Input
                                            id=""
                                            type="select"
                                            value={postulante.secundario}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                handlePostulanteData("secundario", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                            }}
                                        >
                                            {
                                                listBox.map((item: ItemListBox) => {
                                                    const { index, value } = item;
                                                    return (
                                                        <option value={index}>{value}</option>
                                                    )
                                                })
                                            }
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col style={{ minWidth: "20%", width: "20%", maxWidth: "20%" }}>
                                    <FormGroup>
                                        <Label>
                                            Facultades
                                        </Label>
                                        <Input
                                            id="facultades"
                                            value={postulante.facultades}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("facultades", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                borderBottomColor: colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </>
                        }
                        <Col style={{ minWidth: !mostrar ? "80%" : "40%", width: !mostrar ? "80%" : "40%", maxWidth: !mostrar ? "80%" : "40%" }}>
                            <FormGroup>
                                <Label>
                                    Observaciones
                                </Label>
                                <Input
                                    id="observaciones"
                                    value={postulante.observaciones}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 250) return;
                                        handlePostulanteData("observaciones", value);
                                    }}
                                    style={{
                                        borderWidth: 2,
                                        boxShadow: "none",
                                        borderLeftColor: "transparent",
                                        borderTopColor: "transparent",
                                        borderRightColor: "transparent",
                                        borderBottomColor: colors.blue,
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>

                <ModalFooter>
                    <Button
                        style={{ backgroundColor: colors.blue, boxShadow: "none", color: colors.white, fontWeight: "bold", width: 150 }}
                        onClick={() => eliminarPostulante(postulante)}
                    >
                        Eliminar
                    </Button>
                    <Button
                        style={
                            cargandoTabla ?
                                { backgroundColor: colors.blue01, borderColor: colors.blue01, boxShadow: "none", color: colors.white, fontWeight: "bold", width: 150 }
                                :
                                { backgroundColor: colors.blue, boxShadow: "none", color: colors.white, fontWeight: "bold", width: 150 }
                        }
                        onClick={() => guardarDatosPostulante()}
                    >
                        {cargandoTabla ? "Guardando..." : "Guardar"}
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default IndexBaja