import ApiConfig from "../config/Api.config";
import Postulante from "../interfaces/Postulante.interface";
import Request from "../interfaces/Request.interface";
import Response from "../interfaces/Response.interface";
import Tiempo from "../tools/Tiempo.tool";

const url = `${ApiConfig.URLBase}/postulante`

const guardar = async (postulante: Postulante): Promise<Response> => {

    const fecha = Tiempo.getCurrentDate();
    const hora = Tiempo.getCurrentTime();
    postulante = {
        ...postulante,
        fecha,
        hora
    }
    const request: Request = {
        url: `${url}/guardar`,
        method: "POST",
        data: { postulante }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const obtener = async (IdPostulante: number): Promise<Response> => {    
    const request: Request = {
        url: `${url}/obtener/${IdPostulante}`,
        method: "GET",
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const listar = async (textoBuscador: string, fechaInicial: string, fechaFinal: string, principal: string, secundario: string): Promise<Response> => {
    console.log(`${url}/listar`)
    console.log({ textoBuscador, fechaInicial, fechaFinal, principal, secundario })
    const request: Request = {
        url: `${url}/listar`,
        method: "POST",
        data: { textoBuscador, fechaInicial, fechaFinal, principal, secundario }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const listarTuxtla = async (textoBuscador: string, principal: string, secundario: string): Promise<Response> => {    
    const request: Request = {
        url: `${url}/listar/tuxtla`,
        method: "POST",
        data: { textoBuscador, principal, secundario }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const listarBaja = async (textoBuscador: string, principal: string, secundario: string): Promise<Response> => {
    const request: Request = {
        url: `${url}/listar/baja`,
        method: "POST",
        data: { textoBuscador, principal, secundario }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const eliminar = async (IdPostulante: number): Promise<Response> => {
    const request: Request = {
        url: `${url}/eliminar`,
        method: "DELETE",
        data: { IdPostulante }
    }
    const response: Response = await ApiConfig.executeRequest(request);
    return response;
}

const PostulanteService = {
    guardar,
    listar,
    eliminar,
    listarTuxtla,
    listarBaja,
    obtener,
}

export default PostulanteService;