import { ChangeEvent, FC, useEffect, useState } from "react";
import img01 from "../../asset/img/img01.png"
import imgLogo from "../../asset/img/logo.jpg"
import { Button, Card, Col, DropdownItem, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import colors from "../../config/colors.config";
import NavBar from "../../components/public/NavBar.components";
import Block from "../../components/Block.component";
import img02 from "../../asset/img/img02.jpg";
import img03 from "../../asset/img/img03.png";
import img04 from "../../asset/img/img04.jpg";
import img001 from "../../asset/img/00001.webp"
import img003 from "../../asset/img/000002.webp"
import img004 from "../../asset/img/00004.webp"
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Close, Delete } from "@material-ui/icons";
import A from "../../asset/img/A.webp"
import B from "../../asset/img/B.webp"
import C from "../../asset/img/C.webp"
import D from "../../asset/img/D.webp"
import E from "../../asset/img/E.webp"
import { IconButton } from "@mui/material";
import Components from "../../tools/Components.tool";
import { ToastContainer, toast } from "react-toastify";
import Formulario from "../../interfaces/Formulario.interface";
import FormularioService from "../../services/Formulario.service";
import Response from "../../interfaces/Response.interface";
import Swal from "sweetalert2";
import iconFacebook from "../../asset/img/icons/facebook.png"
import iconInstagram from "../../asset/img/icons/instagram.png"
import iconFile from "../../asset/img/icons/iconFile.png"
import iconFileAdd from "../../asset/img/icons/iconFileAdd.png"
import iconGuardia from "../../asset/img/icons/guardia.png"
import Postulante from "../../interfaces/Postulante.interface";
import PostulanteModel from "../../models/Postulante.model";
import ArchivoTool from "../../tools/Archivo.tool";
import { Download } from "@mui/icons-material";
import PostulanteService from "../../services/Postulante.service";
import Device from "../../interfaces/Device.interface";
import DeviceConfig from "../../config/Device.config";
import Numero from "../../tools/Numero.tool";
import Tiempo from "../../tools/Tiempo.tool";
// import Tiempo from "../../tools/Tiempo.tool";

interface AboutItem { Id: number, title: string, text: string | any, img: any }

const Index: FC = () => {

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isOpenModalPostulante, setIsOpenModalPostulante] = useState<boolean>(false);
    const [imgModal, setImgModal] = useState<{ img: any, title: string }>({ img: "", title: "" });
    const [formulario, setFormulario] = useState<Formulario>({ IdFormulario: 0, nombre: "", email: "", mensaje: "", fecha: "1890-01-01", hora: "00:00:00" })
    const [postulante, setPostulante] = useState<Postulante>(PostulanteModel.getEstructura());
    const [estaCargando, setEstaCargando] = useState<boolean>(false);
    const [data, setData] = useState<Device>(DeviceConfig.getData());
    const [isCheck, setIsCheck] = useState<boolean>(false);

    const handleFormularioData = (name: string, value: string) => {
        setFormulario((prev: Formulario) => ({ ...prev, [name]: value }));
    }

    const handlePostulanteData = (name: string, value: string) => {
        setPostulante((prev: Postulante) => ({ ...prev, [name]: value }));
    }

    const itemData = [
        {
            img: A,
            title: 'Breakfast',
        },
        {
            img: B,
            title: 'Burger',
        },
        {
            img: C,
            title: 'Camera',
        },
        {
            img: D,
            title: 'Coffee',
        },
        {
            img: E,
            title: 'Coffee',
        },

    ];


    const aboutItems: AboutItem[] = [
        {
            Id: 1,
            title: "Nuestra Misión",
            text: "En Seguridad Privada Orve, nuestra misión es proporcionar servicios de seguridad confiables y profesionales a nuestros clientes. Nos esforzamos por mantener el más alto nivel de seguridad para que nuestros clientes puedan tener tranquilidad.",
            img: img02
        },
        {
            Id: 2,
            title: "Nuestro Equipo",
            text: "Nuestro equipo de guardias de seguridad altamente capacitados y experimentados está dedicado a garantizar la seguridad y protección de nuestros clientes y sus propiedades. Seleccionamos y evaluamos cuidadosamente a nuestros guardias para asegurarnos de que cumplan con nuestros altos estándares.",
            img: img03
        },
        {
            Id: 3,
            title: "Nuestros Servicios",
            text:
                <>
                    <Label>Ofrecemos una amplia gama de servicios de seguridad entre ellos se encuentran:  </Label>
                    <ul>
                        <li>Guardia de personas </li>
                        <li>Seguridad Intramuros </li>
                        <li>Custodia de valores </li>
                        <li>Capacitación y adiestramiento</li>
                    </ul>
                </>,
            img: img04
        },
    ]

    const validarDatos = (): boolean => {
        const { nombre, email, mensaje } = formulario;
        let value: boolean = true;
        if (nombre.length === 0) value = false;
        if (email.length === 0) value = false;
        if (mensaje.length === 0) value = false;



        return value
    }

    const validarDatosPostulante = (): boolean => {
        let value: boolean = true;

        if (postulante.nombre.length === 0) value = false;
        if (postulante.paterno.length === 0) value = false;
        if (postulante.materno.length === 0) value = false;
        if (postulante.numeroCelular.length === 0) value = false;



        if (postulante.actaNacimiento && postulante.actaNacimiento.length === 0) value = false;

        if (postulante.INE?.length === 0) value = false;
        if (postulante.CURP?.length === 0) value = false;
        if (postulante.comprobanteDomicilio?.length === 0) value = false;
        if (postulante.certificadoEstudios?.length === 0) value = false;
        if (postulante.RFC?.length === 0) value = false;
        if (postulante.solicitudEmpleoFrente?.length === 0) value = false;
        if (postulante.solicitudEmpleoAtras?.length === 0) value = false;
        console.log(postulante.solicitudEmpleoAtras?.length)
        console.log(value)
        if (isCheck === false) {
            value = false;
            Swal.fire({
                icon: "warning",
                title: "Términos y Condiciones",
                text: "Para poder postularte tienes que aceptar nuestros términos y condiciones",
                showConfirmButton: true,
                confirmButtonColor: colors.blue,
                timerProgressBar: true,
            });
        }
        console.log(value)
        return value;
    }

    const guardarDatosPostulante = async (): Promise<void> => {
        const value: boolean = validarDatosPostulante();
        if (value) {
            const response: Response = await PostulanteService.guardar({ ...postulante, status: "I", principal: "POSTULANTE", secundario: "POSTULANTE" });
            const { error } = response;
            if (error === false) {
                Swal.fire({
                    icon: "success",
                    title: "Datos guardados",
                    text: "Tus datos ya fueron registrados, pronto nos pondremos en contacto contigo",
                    showConfirmButton: true,
                    confirmButtonColor: colors.blue,
                    timerProgressBar: true,
                }).then(() => {
                    setEstaCargando(false);
                    setPostulante(PostulanteModel.getEstructura());
                    Tiempo.sleep(0.5).then(() => Components.focus("postulante.nombre"));
                });
                // setEstaCargando(false);
                // setPostulante(PostulanteModel.getEstructura());
                // Tiempo.sleep(0.5).then(() => Components.focus("postulante.nombre"));
            } else {
                setEstaCargando(false);
            }
        } else {
            setEstaCargando(false);
            toast.warn("Falta que cargues documentos.");
        }
    }

    const enviarDatos = async (): Promise<void> => {
        if (validarDatos()) {
            const response: Response = await FormularioService.guardar(formulario)
            const { error } = response;
            if (error === false) {
                Swal.fire({
                    icon: "success",
                    title: "Datos guardados",
                    text: "Tus datos ya fueron registrados, pronto nos pondremos en contacto contigo",
                    showConfirmButton: true,
                    confirmButtonColor: colors.blue,
                    timerProgressBar: true,
                });
                setEstaCargando(false);
            }
        } else {
            toast.warn("Falta llenar algunos de los campos");
        }
    }

    useEffect(() => {
        cargarDatos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cargarDatos = (): void => {
        setData(DeviceConfig.getData());
    }

    return (
        <>
            <ToastContainer limit={3} position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="light" />
            <NavBar />
            <IconButton
                style={{ position: "fixed", zIndex: 999, backgroundColor: colors.white, marginTop: 100, marginLeft: data.isDesktop ? "95%" : "78%", }}
                title={"Haz click aquí para postularte"}
                onClick={() => {
                    setIsOpenModalPostulante(true);
                }}
            >
                <img src={iconGuardia} alt="/*-" style={{ width: 50, height: 50 }} />
            </IconButton>
            <section
                id="home"
                style={{
                    width: "100%",
                    height: data.isDesktop ? "100vh" : "auto",
                    minHeight: "100vh",
                    // color: colors.gpm.cafe,
                    background: `url(${img01}) no-repeat center center/cover fixed`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >

                <div style={{ marginTop: data.isMovil ? 120 : 0 }} >
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <img src={imgLogo} alt="/*-" style={{ height: 200, borderRadius: "50%" }} />
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <Label style={{ color: colors.white, fontSize: 32, fontWeight: "bold", marginTop: 10 }} >
                                GRUPO ORVE
                            </Label>
                        </Col>
                    </Row>

                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: data.isDesktop ? 50 : 10 }} >
                        <Col style={{ textAlign: "center" }} >
                            <Label style={{ color: colors.white, fontSize: data.isDesktop ? 48 : 24, fontWeight: "bold" }} >
                                TENEMOS TODO PARA PROTEGER LO MÁS VALIOSO QUE TIENES
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0 }} >
                        <Col style={{ textAlign: "center" }} >
                            <Label style={{ color: colors.white, fontSize: data.isDesktop ? 32 : 24 }} >
                                Guardias de seguridad intramuros a un precio justo.
                            </Label>
                        </Col>
                    </Row>
                    <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10, fontWeight: "bold" }} >
                        <Col style={{ textAlign: "center" }} >
                            <Button
                                style={{ backgroundColor: colors.white, color: colors.black, fontWeight: "bold", height: 70, width: 200, fontSize: 20 }}
                                onClick={() => window.location.href = "#contacto"}
                            >
                                CONTÁCTENOS
                            </Button>
                        </Col>
                    </Row>
                    {
                        data.isMovil &&
                        <Block height={"10vh"} />
                    }
                </div>
            </section >
            <section
                id="acerca-de-nosotros"
                style={{ width: "100%", height: "auto", minHeight: "auto", backgroundColor: colors.white }}
            >
                <Block height={70} />
                <Row style={{ marginRight: 0, marginLeft: 0, fontWeight: "bold" }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Acerca de Nosotros
                        </Label>
                    </Col>
                </Row>
                {
                    aboutItems.map((item: AboutItem) => {
                        const { Id, title, text, img } = item;
                        if (Id % 2 !== 0) {
                            return (
                                <Row style={{ width: data.isDesktop ? "80%" : "96%", marginTop: 50, marginLeft: data.isDesktop ? "10%" : "2%", marginRight: data.isDesktop ? "10%" : "2%" }} >
                                    {
                                        data.isDesktop &&
                                        <Col xs="12" md="6" style={{ minWidth: data.isDesktop ? "50%" : "100%", width: data.isDesktop ? "50%" : "100%", maxWidth: data.isDesktop ? "50%" : "100%" }} >
                                            <img src={img} alt="/*-" style={{ height: "100%", width: "100%" }} />
                                        </Col>
                                    }
                                    <Col xs="12" md="6" style={{ minWidth: data.isDesktop ? "50%" : "100%", width: data.isDesktop ? "50%" : "100%", maxWidth: data.isDesktop ? "50%" : "100%" }} >
                                        <Row>
                                            <Col>
                                                <Label style={{ fontWeight: "bold", fontSize: 36 }} >
                                                    {title}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: "justify" }} >
                                                <Label style={{ fontSize: 24 }} >
                                                    {text}
                                                </Label>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            );
                        } else {
                            return (
                                <Row style={{ width: data.isDesktop ? "80%" : "96%", marginTop: 50, marginLeft: data.isDesktop ? "10%" : "2%", marginRight: data.isDesktop ? "10%" : "2%" }} >
                                    <Col xs="12" md="6" style={{ minWidth: data.isDesktop ? "50%" : "100%", width: data.isDesktop ? "50%" : "100%", maxWidth: data.isDesktop ? "50%" : "100%" }} >
                                        <Row>
                                            <Col>
                                                <Label style={{ fontWeight: "bold", fontSize: 36 }} >
                                                    {title}
                                                </Label>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col style={{ textAlign: "justify" }} >
                                                <Label style={{ fontSize: 24 }} >
                                                    {text}
                                                </Label>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {
                                        data.isDesktop &&
                                        <Col xs="12" md="6" style={{ minWidth: "50%", width: "50%", maxWidth: "50%" }} >
                                            <img src={img} alt="/*-" style={{ height: "100%", width: "100%" }} />
                                        </Col>
                                    }
                                </Row>
                            );
                        }

                    })
                }
                <Block height={"10vh"} />
            </section>
            <section
                id="tu-bienestar"
                // style={{ width: "100%", height: "100vh", backgroundColor: colors.white }}
                style={{ width: "100%", height: "auto", minHeight: "auto", backgroundColor: colors.white }}
            >
                <Block height={70} />
                <Row style={{ marginRight: 0, marginLeft: 0, fontWeight: "bold" }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Tu Bienestar es Nuestra Motivación
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: "5%", marginLeft: "5%", marginTop: 30 }} >
                    <Col style={{ textAlign: "center" }} >
                        <Row>
                            <img src={img001} alt="*-" style={{ borderRadius: "50%", height: 300, width: 400 }} />
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.blue, fontSize: 38, fontWeight: "bold" }} >
                                Registro ante la SSPC
                            </Label>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.black, fontSize: 22 }} >
                                Personal capacitado e inscrito ante la Secretaria de Seguridad y Protección Ciudadana.
                            </Label>
                        </Row>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Row>
                            <img src={img003} alt="*-" style={{ borderRadius: "50%", height: 300, width: 400 }} />
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.blue, fontSize: 38, fontWeight: "bold" }} >
                                Protocolos de Prevención
                            </Label>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.black, fontSize: 22 }} >
                                Implementamos medidas de seguridad para la comisión de delitos.
                            </Label>
                        </Row>
                    </Col>
                    <Col style={{ textAlign: "center" }} >
                        <Row>
                            <img src={img004} alt="*-" style={{ borderRadius: "50%", height: 300, width: 400 }} />
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.blue, fontSize: 38, fontWeight: "bold" }} >
                                Monitoreo 24 horas
                            </Label>
                        </Row>
                        <Row style={{ marginTop: 10 }} >
                            <Label style={{ color: colors.black, fontSize: 22 }} >
                                Aplicamos un seguimiento constante en nuestros servicios.
                            </Label>
                        </Row>
                    </Col>
                </Row>

            </section>
            <section
                // style={{ width: "100%", height: "100vh" }}
                // style={{ width: "100%", height: "auto", minHeight: "auto", backgroundColor: colors.white }}
                style={{ width: "100%", height: "auto", minHeight: "100vh", backgroundColor: colors.white }}
                id="seguridad-y-vigilancia-especializada"
            >
                {/* {
                    data.isMovil &&
                    <Block height={"40vh"} />
                } */}
                <Block height={"10vh"} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Seguridad y Vigilancia Especializada
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <div style={{ width: '100%', height: '10vh', margin: '0 auto' }}>
                        <ImageList sx={{ width: "100%", height: "80vh" }} cols={3} rowHeight={164}>
                            {itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                                        alt={item.title}
                                        loading="lazy"
                                        onClick={() => {
                                            setImgModal(item)
                                            setIsOpenModal(true)
                                        }}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </div>
                </Row>
            </section>

            <section
                id="contacto"
                // style={{ width: "100%", height: "100vh", backgroundColor: "#F6F6F6" }}
                style={{ width: "100%", height: "110vh", minHeight: "110vh", backgroundColor: "#F6F6F6" }}
            >
                <Block height={70} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Contáctanos
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: data.isDesktop ? "10%" : "2%", marginLeft: data.isDesktop ? "10%" : "2%" }} >
                    <Col xs="12" md="6" >
                        <Row>
                            <Col>
                                <Label style={{ fontSize: 24 }} >
                                    No dejes pasar la oportunidad de asegurar tus activos!
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label>
                                    Nombre(s)
                                </Label>
                                <Input
                                    id="formulario.nombre"
                                    value={formulario.nombre}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return;
                                        handleFormularioData("nombre", value);
                                    }}
                                    style={{
                                        borderWidth: 2,
                                        boxShadow: "none",
                                        borderLeftColor: "transparent",
                                        borderTopColor: "transparent",
                                        borderRightColor: "transparent",
                                        borderBottomColor: colors.blue,
                                    }}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label>
                                    Correo
                                </Label>
                                <Input
                                    id="formulario.email"
                                    value={formulario.email}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 100) return;
                                        handleFormularioData("email", value);
                                    }}
                                    style={{
                                        borderWidth: 2,
                                        boxShadow: "none",
                                        borderLeftColor: "transparent",
                                        borderTopColor: "transparent",
                                        borderRightColor: "transparent",
                                        borderBottomColor: colors.blue,
                                    }}
                                />
                            </FormGroup>
                        </Row>
                        <Row>
                            <FormGroup>
                                <Label>
                                    Mensaje
                                </Label>
                                <Input
                                    id="formulario.mensaje"
                                    value={formulario.mensaje}
                                    onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                        if (value.length > 250) return;
                                        handleFormularioData("mensaje", value);
                                    }}
                                    type={"textarea"}
                                    style={{
                                        borderWidth: 2,
                                        boxShadow: "none",
                                        borderLeftColor: "transparent",
                                        borderTopColor: "transparent",
                                        borderRightColor: "transparent",
                                        minHeight: 100,
                                        borderBottomColor: colors.blue,
                                    }}
                                />
                                <Row>
                                    <Col style={{ textAlign: "right" }} >
                                        <samp>{formulario.mensaje.length}/250</samp>
                                    </Col>
                                </Row>

                            </FormGroup>

                        </Row>
                        <Row>
                            <Col style={{ textAlign: "right" }} >
                                <Button
                                    style={{ boxShadow: "none", backgroundColor: colors.white, color: colors.blue, borderColor: colors.blue, width: 125, marginRight: 10 }}
                                    onClick={() => {
                                        setFormulario({ IdFormulario: 0, nombre: "", email: "", mensaje: "", fecha: "1890-01-01", hora: "00:00:00" })
                                        Components.focus("formulario.nombre");
                                    }}
                                >
                                    Cancelar
                                </Button>
                                <Button
                                    style={
                                        estaCargando ?
                                            { boxShadow: "none", backgroundColor: colors.blue01, borderColor: colors.blue01, color: colors.white, width: 125 }
                                            :
                                            { boxShadow: "none", backgroundColor: colors.blue, borderColor: colors.blue, color: colors.white, width: 125 }
                                    }
                                    onClick={async () => {
                                        await setEstaCargando(true);
                                        // await Tiempo.sleep(2);
                                        await enviarDatos();
                                    }}
                                >
                                    {estaCargando ? "Enviando..." : "Enviar"}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs="12" md="6">
                        <Row>
                            <Col>
                                <Label style={{ fontSize: 24 }} >
                                    Contáctanos para pedir una cotización.
                                </Label>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 30 }} >
                            <Label style={{ fontSize: 18 }} >
                                Horarios de Lunes a Viernes de 09:00 am a 06:00 pm
                            </Label>
                        </Row>
                        <Row style={{ marginTop: 60 }} >
                            <Label style={{ fontSize: 18 }} >
                                Seguridad Privada Orve
                            </Label>
                        </Row>
                        <Row>
                            <Label>
                                Avenida Presa Malpaso 202, 29040 Tuxtla Gutiérrez, Chiapas, Mexico
                            </Label>
                        </Row>
                        <Row>
                            <Label
                                style={{ color: colors.blue, textDecorationLine: "underline", cursor: "pointer" }}
                                onClick={() => window.location.href = "#mapa"}
                            >
                                Ver mapa
                            </Label>
                        </Row>

                    </Col>
                </Row >
                <Block height={70} />
            </section >

            <section
                id="mapa"
                style={{ width: "100%", height: "150vh" }}
            >
                <Block height={70} />
                <Row style={{ marginRight: 0, marginLeft: 0 }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Conoce nuestras oficinas
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: "5%", marginLeft: "5%" }} >
                    <Col>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2701.4577292933755!2d-93.09067085293881!3d16.754862585111393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ed277a6c861421%3A0x39826035eb90f0d6!2sGrupo%20Orve%20Seguridad%20Privada!5e0!3m2!1ses!2smx!4v1722208174999!5m2!1ses!2smx"
                            height={500}
                            style={{ borderRadius: 12, width: "100%", }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Mapa"
                        />
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 50 }} >
                    <Col style={{ textAlign: "center" }} >
                        <Label style={{ color: colors.blue, fontSize: 36, marginLeft: 20 }} >
                            Síguenos en nuestras redes
                        </Label>
                    </Col>
                </Row>
                <Row style={{ marginRight: 0, marginLeft: 0, marginTop: 10 }} >
                    <Col style={{ textAlign: "center" }} >
                        <img
                            src={iconFacebook} alt="/*-"
                            style={{ height: 70, width: 70, marginRight: 30, cursor: "pointer" }}
                            onClick={() => window.open("https://www.facebook.com/profile.php?id=100014991154792")}
                        />
                        <img
                            src={iconInstagram}
                            alt="/*-"
                            style={{ height: 70, width: 70, cursor: "pointer" }}
                            onClick={() => window.open("https://www.instagram.com/orve_seguridad_privada/")}
                        />
                    </Col>
                </Row>
            </section>


            <Modal

                isOpen={isOpenModal}
                style={{ maxWidth: "80%", fontFamily: '"Fira Sans", sans-serif' }}
            >
                <ModalHeader
                    style={{ backgroundColor: "#014F93" }}
                    close={

                        <IconButton
                            onClick={() => {
                                setIsOpenModal(false);
                            }}
                            style={{ backgroundColor: colors.white, color: colors.blue }}
                        >
                            <Close />
                        </IconButton>

                    }
                >

                </ModalHeader>
                <ModalBody>
                    <Row>

                        <center>
                            <img
                                srcSet={`${imgModal.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                src={`${imgModal.img}?w=164&h=164&fit=crop&auto=format`}
                                alt={"/*-"}
                                loading="lazy"
                            />
                        </center>
                    </Row>

                </ModalBody>
            </Modal >
            <Modal
                isOpen={isOpenModalPostulante}
                style={{ maxWidth: data.isDesktop ? "90%" : "100%", fontFamily: '"Fira Sans", sans-serif' }}
                onOpened={() => { Components.focus("postulante.nombre") }}
            >
                <ModalHeader
                    style={{ backgroundColor: "#014F93" }}
                    close={
                        <IconButton
                            onClick={() => { setIsOpenModalPostulante(false); }}
                            style={{ backgroundColor: colors.white, color: colors.blue }}
                        >
                            <Close />
                        </IconButton>
                    }
                >
                    <Label style={{ fontSize: 28, color: colors.white, fontFamily: 'Fira Sans' }} >
                        Ingresa tus datos
                    </Label>
                </ModalHeader>
                <ModalBody style={{ minHeight: "70vh", height: "70vh", maxHeight: "70vh", overflowY: "scroll" }} >
                    <Row>
                        <Col style={{}} >
                            <Row>
                                <Col sm={data.isMovil ? "4" : ""} md={data.isMovil ? "4" : ""} lg={data.isMovil ? "4" : ""} className={data.isMovil ? "mb-3" : ""} >
                                    <FormGroup>
                                        <Label>
                                            Nombre(s)
                                        </Label>
                                        <Input
                                            id="postulante.nombre"
                                            value={postulante.nombre}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("nombre", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                // borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={data.isMovil ? "4" : ""} md={data.isMovil ? "4" : ""} lg={data.isMovil ? "4" : ""} className={data.isMovil ? "mb-3" : ""} >
                                    <FormGroup>
                                        <Label>
                                            Apellido Paterno
                                        </Label>
                                        <Input
                                            id="postulante.nombre"
                                            value={postulante.paterno}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("paterno", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                // borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={data.isMovil ? "4" : ""} md={data.isMovil ? "4" : ""} lg={data.isMovil ? "4" : ""} className={data.isMovil ? "mb-3" : ""} >
                                    <FormGroup>
                                        <Label>
                                            Apellido Materno
                                        </Label>
                                        <Input
                                            id="postulante.materno"
                                            value={postulante.materno}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 100) return;
                                                handlePostulanteData("materno", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                // borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col sm={data.isMovil ? "4" : ""} md={data.isMovil ? "4" : ""} lg={data.isMovil ? "4" : ""} className={data.isMovil ? "mb-3" : ""} >
                                    <FormGroup>
                                        <Label>
                                            Celular
                                        </Label>
                                        <Input
                                            id="postulante.numeroCelular"
                                            value={postulante.numeroCelular}
                                            onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                                                if (value.length > 13) return;
                                                value = Numero.formatPhoneNumber(value);
                                                handlePostulanteData("numeroCelular", value);
                                            }}
                                            style={{
                                                borderWidth: 2,
                                                boxShadow: "none",
                                                borderLeftColor: "transparent",
                                                borderTopColor: "transparent",
                                                borderRightColor: "transparent",
                                                // borderBottomColor: isHovered[0] ? colors.blue : colors.blue,
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Acta de Nacimiento
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.actaNacimiento === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("actaNacimiento")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.actaNacimiento !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, actaNacimiento: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.actaNacimiento)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.actaNacimiento === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.actaNacimiento ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="actaNacimiento"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, actaNacimiento: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        INE
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.INE === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("INE")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.INE !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, INE: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.INE)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.INE === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.INE ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="INE"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, INE: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        CURP
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.CURP === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("CURP")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.CURP !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, CURP: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.CURP)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.CURP === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.CURP ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="CURP"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, CURP: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Comprobante de Domicilio (Menor a 3 meses)
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.comprobanteDomicilio === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("comprobanteDomicilio")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.comprobanteDomicilio !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, comprobanteDomicilio: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.comprobanteDomicilio)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.comprobanteDomicilio === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.comprobanteDomicilio ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="comprobanteDomicilio"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, comprobanteDomicilio: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Certificado de Estudios
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.certificadoEstudios === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("certificadoEstudios")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.certificadoEstudios !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, certificadoEstudios: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.certificadoEstudios)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.certificadoEstudios === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.certificadoEstudios ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="certificadoEstudios"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, certificadoEstudios: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        RFC
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.RFC === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("RFC")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.RFC !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, RFC: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.RFC)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.RFC === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.RFC ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="RFC"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, RFC: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Solicitud de Empleo Frente
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.solicitudEmpleoFrente === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("solicitudEmpleoFrente")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.solicitudEmpleoFrente !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoFrente: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.solicitudEmpleoFrente)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.solicitudEmpleoFrente === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.solicitudEmpleoFrente ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="solicitudEmpleoFrente"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoFrente: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Solicitud de Empleo Atrás
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.solicitudEmpleoAtras === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("solicitudEmpleoAtras")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.solicitudEmpleoAtras !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoAtras: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.solicitudEmpleoAtras)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.solicitudEmpleoAtras === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.solicitudEmpleoAtras ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="solicitudEmpleoAtras"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, solicitudEmpleoAtras: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                        <Col sm="4" md="4" lg="4" className="mb-3"  >
                            <Card className="custom-card" style={{ backgroundColor: colors.white, padding: 10 }}>
                                <FormGroup>
                                    <Label style={{ color: colors.black, fontSize: 16 }} >
                                        Carta de Recomendación (opcional)
                                    </Label>
                                    <DropdownItem divider />
                                    <Row style={{ marginBottom: 0 }} >
                                        <Col>
                                            <img
                                                src={postulante.cartaRecomendacion === "" ? iconFileAdd : iconFile}
                                                alt="/*-"
                                                style={{ height: 60, width: 60, cursor: "pointer", marginTop: 5 }}
                                                onClick={() => {
                                                    Components.click("cartaRecomendacion")
                                                }}
                                            />
                                        </Col>
                                        {
                                            postulante.cartaRecomendacion !== "" &&
                                            <>
                                                <Col style={{ textAlign: "right" }} >
                                                    <IconButton
                                                        style={{ color: colors.red, }}
                                                        onClick={() => setPostulante((prev: Postulante) => ({ ...prev, cartaRecomendacion: "" }))}
                                                    >
                                                        <Delete />
                                                    </IconButton>

                                                    <IconButton
                                                        style={{ color: colors.blue, marginLeft: 10 }}
                                                        onClick={() => ArchivoTool.descargarArchivoDesdeBase64(postulante.cartaRecomendacion)}
                                                    >
                                                        <Download />
                                                    </IconButton>

                                                </Col>
                                            </>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Label>
                                                {postulante.cartaRecomendacion === "" ? "No se ha cargado un archivo" : ArchivoTool.obtenerNombreArchivo(postulante.cartaRecomendacion ?? "")}
                                            </Label>
                                        </Col>
                                    </Row>
                                    <Input
                                        id="cartaRecomendacion"
                                        type="file"
                                        accept="application/pdf"
                                        onChange={async ({ target: { files } }: ChangeEvent<HTMLInputElement>) => {
                                            if (files !== null) {
                                                const file: File = files[0]
                                                try {
                                                    const base64: string = await ArchivoTool.convertirBase64(file);
                                                    setPostulante((prev: Postulante) => ({ ...prev, cartaRecomendacion: base64 }))
                                                } catch (error) {
                                                    console.error({ error })
                                                }
                                            }
                                        }}
                                        hidden
                                    />
                                </FormGroup>

                            </Card>

                        </Col>
                    </Row>
                    <Row>
                        <Col style={{ textAlign: "right" }} >
                            <Label>
                                Para poder postulante tienes que aceptar los términos y condiciones
                            </Label>
                            <br />
                            <Label>
                                Marca la casilla para indicar que los aceptas
                            </Label>
                            <Input
                                type="checkbox"
                                checked={isCheck}
                                style={{ marginLeft: 15 }}
                                onClick={() => {
                                    let value = isCheck;
                                    setIsCheck(!value);
                                }}
                            />
                            <br />
                            <Label
                                style={{ color: colors.blue, textDecorationLine: "underline", cursor: "pointer" }}
                                onClick={() => {
                                    window.open("/tyc");
                                }}
                            >
                                No conoces nuestros términos y condiciones, haz click aquí.
                            </Label>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        style={{ backgroundColor: colors.white, boxShadow: "none", color: colors.blue, borderColor: colors.blue, fontWeight: "bold", width: 150 }}
                        onClick={() => setPostulante(PostulanteModel.getEstructura())}
                    >
                        Cancelar
                    </Button>
                    <Button
                        style={
                            estaCargando ?
                                { backgroundColor: colors.blue01, borderColor: colors.blue01, boxShadow: "none", color: colors.white, fontWeight: "bold", width: 150 }
                                :
                                { backgroundColor: colors.blue, borderColor: colors.blue, boxShadow: "none", color: colors.white, fontWeight: "bold", width: 150 }
                        }
                        onClick={async () => {
                            await setEstaCargando(true);
                            // await Tiempo.sleep(2);
                            await guardarDatosPostulante();
                        }}
                    >
                        {estaCargando ? "Postulándome..." : "Postularme"}
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    )
}

export default Index
